.Home {
  margin-left: 250px;
  margin-top: 70px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
body {
  overflow-x: hidden;
  background-color: #f8f9fa;
}
body::-webkit-scrollbar {
  width: 0.4em;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: #891c69;
  outline: 1px solid slategrey;
}
.side-bar::-webkit-scrollbar {
  width: 0.3em;
}
.side-bar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-top: 70px solid #fff;
}
.Dash {
  display: grid;
  grid-template-columns: 8fr 3fr;
  gap: 20px;
}
.fit {
  width: fit-content;
}
.after::after {
  content: '';
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 100%;
  border-bottom: 2px solid #891c69;
}
.hpx {
  height: 400px;
}
.svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.transparent {
  background-color: transparent;
}
.border-bottom {
  border-bottom: 1px;
}
.contain {
  display: inline-block;
  position: absolute;
  width: 200%;
  padding-bottom: 100%;
  vertical-align: middle;
  overflow: hidden;
  top: 0;
  left: 0;
}
.clip {
  clip-path: circle(45.7% at 74% 22%);
  background-color: #ece9e9;
}
.card {
  display: grid;
  grid-template-columns: 8fr 4fr;
}
.min-h {
  min-height: 100vh;
}
.gray {
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(128, 128, 128, 0.164);
}
.bank-select {
  display: grid;
  grid-template-columns: 5fr 5fr;
}
.fm input,
.fm select {
  border: 1px solid rgba(128, 128, 128, 0.363);
  padding: 6px;
  border-radius: 6px;
}
.filter {
  min-height: 330px;
}
.g-border {
  border: 2px solid #891c69;
}
.wd {
  width: 50%;
}
.modal-grid {
  grid-template-columns: 6fr 4fr;
}
.mg {
  margin-left: 20px;
}
.m-t {
  margin-top: 20px;
}
.card2 {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 20px;
  min-height: 100vh;
}
.grooming-color {
  background-color: #891c69;
}
.grooming-color2:hover {
  background-color: #974d7b;
}
.grooming-color4:hover {
  background-color: #891c69;
}
.grooming-color3 {
  background-color: #974d7b;
}
.border-g {
  border: 1px solid #974d7b;
}
.border-bottom {
  border-bottom: 2px solid #891c69;
}
.bordernone {
  border: none;
  width: 100%;
  outline: none !important;
  background-color: transparent;
  border-bottom: 1px solid #000;
}

.h-250 {
  height: 350px;
}
.filt {
  filter: brightness(0) invert(1);
}
.w-400 {
  width: 600px;
}
@media screen and (max-width: 769px) {
  .Home {
    margin-left: 0;
  }
  .Dash {
    display: block;
  }
  .card {
    display: block;
  }
}
.white {
  background-color: rgba(128, 128, 128, 0.527);
}
@media screen and (max-width: 1200px) {
  .card {
    display: block;
  }
  .mg {
    margin-left: 0;
    margin-top: 20px;
  }
  .card2 {
    display: block;
    gap: 0px;
  }
  .mar {
    margin-top: 20px;
  }
  .h {
    height: auto;
  }
}
